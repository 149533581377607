import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {useCallback, useState} from 'react';
import {iTimedMessageMessageItem} from './TimedMsgRequestContentMessageCard';
import {IMediaTrack} from '../../../../../dto/MediaTrack.dto';
import {useStylesed} from '../../../../../components/styles/commonStyled';
import TimedMsgNewSongRequest from './TimedMsgNewSongRequest';
import MusicSelectionTable from '../../../../../components/music/MusicSelectionTable';
import IconAdd from '../../../../../asset/icons/icon_addsongs.svg';
import IconDeleteButton from '../../../../../components/Button/IconDeletebutton';
import {IStation} from '../../../../../dto/Station.dto';
import AudioDurationValidation, {
  getAudioDurationFromFile,
} from '../../../../../components/utlis/Audio/GetAudioDuration';

type Props = {
  message: iTimedMessageMessageItem;
  onChange: (newMessage: iTimedMessageMessageItem) => void;
  type?: string;
  label?: string;
  selectedStations?: IStation[];
};

const TimedMsgRequestContentSongCard = ({
  onChange,
  message,
  type,
  label,
  selectedStations,
}: Props) => {
  const handleSelect = (track: IMediaTrack) => {
    onChange({
      ...message,
      artist: '',
      title: '',
      file: undefined,
      track,
      isNew: true,
    });
  };
  const handleDeselect = () => {
    onChange({
      ...message,
      artist: '',
      title: '',
      file: undefined,
      track: undefined,
    });
  };
  return (
    <Grid
      container
      spacing={1}
      className={`w-full ${
        type === 'sequence' ? 'black-variant1 px-2 pb-2 radius-5' : ''
      }`}>
      <Grid item xs={12} className='w-full'>
        <MusicSelectionTable
          selectedTracks={message.track ? [message.track] : []}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          slots={{}}
          title={label ? `Select ${label}` : 'Timed Songs'}
          selectedStations={selectedStations}
          selection_type='timed-message'
          isTrackWithoutGenreRestriction={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className='p-5 radius-10 blue-variant4 flex items-center justify-center'>
        {message.id > 0 && !message.track ? (
          <Stack direction='column' spacing={2}>
            <Alert severity='warning'>
              Selecting song will delete the the song-request which is
              In-progress
            </Alert>
            <Typography align='center'>Song request is In-progress</Typography>
          </Stack>
        ) : (
          <>
            {message.track ? (
              <SelectedSongCmpt track={message.track} />
            ) : (
              <Box>
                {message.file ? null : (
                  <TimedMsgNewSongRequest
                    message={message}
                    onChange={onChange}
                  />
                )}

                {message.artist || message.title || message.file ? null : (
                  <Divider sx={{width: '100%'}}>
                    <Chip label='OR' />
                  </Divider>
                )}
                {message.artist || message.title ? null : (
                  <SongUploadCmpt message={message} onChange={onChange} />
                )}
              </Box>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TimedMsgRequestContentSongCard;

function SelectedSongCmpt({track}: Readonly<{track: IMediaTrack}>) {
  return (
    <TableContainer>
      <Table>
        <TableRow>
          <TableCell className='w-200'>
            <Typography variant='h6' className='text-white-50'>
              Title
            </Typography>
          </TableCell>
          <TableCell>
            {' '}
            <Typography variant='h6' className='text-white-50'>
              Artist
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{track?.track_artist}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{track?.track_name}</Typography>
          </TableCell>
        </TableRow>
      </Table>
    </TableContainer>
  );
}

type iSongUploadProps = {
  message: iTimedMessageMessageItem;
  onChange: (message: iTimedMessageMessageItem) => void;
};

function SongUploadCmpt({message, onChange}: Readonly<iSongUploadProps>) {
  const classes = useStylesed();
  const [fileDuration, setFileDuration] = useState(0);

  const handleOnChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target?.files && e.target?.files.length) {
        const file = e.target.files[0] as File;
        const duration = await getAudioDurationFromFile(file);
        setFileDuration(duration);
        onChange({
          ...message,
          track: undefined,
          artist: '',
          title: '',
          file,
          fileDuration: duration,
        });
      }
    },
    [message, onChange],
  );

  const handleRemove = () => {
    setFileDuration(0);
    onChange({
      ...message,
      file: undefined,
      fileDuration: 0,
    });
  };

  return (
    <Stack className={classes.c_center} spacing={2} mt={2}>
      <Typography>
        Upload a song from your computer. We will review it.
      </Typography>
      <AudioDurationValidation
        durationInSec={fileDuration}
        maxDurationInMin={5}
      />
      {message.file ? (
        <Typography>
          {message.file.name} <IconDeleteButton onClick={handleRemove} />
        </Typography>
      ) : null}
      <Button
        component='label'
        variant='outlined'
        startIcon={<img src={IconAdd} alt='Upload Icon' />}>
        Upload Song
        <input
          accept='audio/*'
          type='file'
          id='select-voice'
          style={{
            display: 'none',
          }}
          value=''
          onChange={handleOnChange}
        />
      </Button>
    </Stack>
  );
}
