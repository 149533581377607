import {createSelector} from 'reselect';
import {createAction, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../configstore';
import {loginSuccess, selectedUserCompany} from './userSlice';
import {ICompanyDetails} from '../../dto/Company.dto';

export interface CompanyState extends Omit<ICompanyDetails, 'created_at'> {
  isInternalStaff: boolean;
}
const initialState: CompanyState = {
  id: 0,
  name: '',
  company_code: '',
  product: {
    id: 0,
    cmc_product: '',
    primary: '',
    secondary: [{id: 0, secondary: '', name: ''}],
    name: '',
  },
  secondary_product: [{id: 0, secondary: '', name: ''}],
  product_level: {
    id: 0,
    level: '',
    name: '',
    sponser_commercials: 0,
  },
  company_detail: {
    address1: '',
    address2: '',
    phone: '',
    city: '',
    state_id: 0,
    state: '',
    zip: '',
    country: '',
    email: '',
    is_participation_enabled: false,
    branding: '',
  },
  psm: {id: 0, name: '', email: ''},
  users: null,
  tnn: {id: 0, sponsor_option_id: 0},
  pr: null,
  isInternalStaff: false,
  music_type: '',
};

export const selectCompany = createAction<ICompanyDetails>('selectCompany');

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginSuccess, (_state, {payload}) => {
      const company = payload.user.company;
      if (company !== undefined) {
        return {...initialState, ...company, isInternalStaff: false};
      }
      return initialState;
    });
    builder.addCase(selectedUserCompany, (_state, {payload}) => {
      return {...initialState, ...payload.company, isInternalStaff: false};
    });
    builder.addCase(selectCompany, (_state, {payload}) => {
      sessionStorage.setItem('impersonated_company', payload.id.toString());
      return {...initialState, ...payload, isInternalStaff: true};
    });
  },
});

const companySelector = (state: RootState) => state.company;
export const productSelector = (state: RootState) => state.company.product;
export const productLevelSelector = (state: RootState) => {
  return state.company.product_level ? state.company.product_level.level : '';
};
export const companyMusicTypeSelector = createSelector(
  companySelector,
  state => state.music_type,
);
export const companyIdSelector = createSelector(
  companySelector,
  state => state.id,
);
export const companyNameSelector = createSelector(
  companySelector,
  state => state.name,
);
export const isGTSelector = createSelector(
  productSelector,
  product => product.cmc_product === 'GameTime',
);
export const isNRSelector = createSelector(
  productSelector,
  product => product.cmc_product === 'Neptune Radio',
);
export const isSZSelector = createSelector(
  productSelector,
  product => product.cmc_product === 'SchoolZone',
);

export const isNRorSZSelector = createSelector(
  productSelector,
  product =>
    product.cmc_product === 'Neptune Radio' ||
    product.cmc_product === 'SchoolZone',
);

export const companyIsImpersonateSelector = createSelector(
  companySelector,
  state => state.isInternalStaff,
);

export const isTrampolineSelector = createSelector(
  productSelector,
  product =>
    product.cmc_product !== 'GameTime' && product.primary === 'Trampoline',
);

export const companyProductTypeSelector = createSelector(
  productSelector,
  product => product.cmc_product,
);

export const companyDetailSelector = createSelector(
  companySelector,
  state => state.company_detail,
);
