import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  DataGridPro,
  UncapitalizedGridProSlotsComponent,
} from '@mui/x-data-grid-pro';
import {Box, Typography} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {DataGridCustPagination} from '../utlis/DataGrid/Pagination';
import MusicPreviewBtn, {MusicPreviewBtnDisable} from './MusicPreviewBtn';
import {defaultDataGridProSearchSort} from '../../dto/Html.dto';
import {useStoreSelector} from '../../store/configstore';
import {companyIdSelector} from '../../store/Slices/companySlice';
import {
  useRqMediaTracksPaginate,
  useRqMediaTracksWithoutCompanyPaginate,
} from '../../react_query/media_tracks/ListMediaTrackPaginatPro';
import InputSearch from '../styles/InputSearch';
import {NoPlayInfoButton} from '../../pages/admin/no-play/NoPlayInfoButton';
import {SongSelectionRemoveButton} from '../Button/SongSelectionRemoveButton';
import {SongSelectionAddButton} from '../Button/SongSelectionAddButton';
import {GridDurationColumn} from '../grid/GridColumns';
import {IStation} from '../../dto/Station.dto';

type Props = {
  selectedTracks: IMediaTrack[];
  onSelect: (track: IMediaTrack) => void;
  onDeselect: (track: IMediaTrack) => void;
  isAddDisabled?: boolean;
  company_id?: number;
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  title?: string;
  selectedStations?: IStation[];
  type?: string;
  musicType?: string;
  isTrackWithoutGenreRestriction?: boolean;
  selection_type?: string;
};

const MusicSelectionTable = ({
  selectedTracks,
  onSelect,
  onDeselect,
  isAddDisabled,
  company_id,
  musicType,
  slots = {pagination: DataGridCustPagination},
  title,
  isTrackWithoutGenreRestriction,
  selectedStations,
  type,
  selection_type,
}: Props) => {
  const [selectedTrackIds, setSelectedTrackIds] = useState(() =>
    selectedTracks.map(o => o.id),
  );
  const [query, setQuery] = useState({
    ...defaultDataGridProSearchSort,
    ...(selectedStations && {station_id: selectedStations.map(val => val.id)}),
    ...(type && {type: type}),
    ...(selection_type && {selection_type: selection_type}),
  });

  const companySelector = useStoreSelector(companyIdSelector);
  const companyId = company_id ?? companySelector;

  const {data, isLoading} = useRqMediaTracksWithoutCompanyPaginate(
    query,
    isTrackWithoutGenreRestriction,
  );
  const {data: mediaTrackData, isLoading: mediaTrackIsLoading} =
    useRqMediaTracksPaginate(query, companyId, !isTrackWithoutGenreRestriction);

  const trackDetails = useMemo(() => {
    if (!mediaTrackIsLoading || !isLoading) {
      return mediaTrackData?.data || data?.data || [];
    }
    return [];
  }, [data, mediaTrackData, mediaTrackIsLoading, isLoading]);

  const updateQuery = useCallback(
    updates => {
      setQuery(prev => ({...prev, ...updates}));
    },
    [setQuery],
  );

  useEffect(() => {
    setSelectedTrackIds(selectedTracks.map(o => o.id));
    if (musicType) updateQuery({type: musicType});
  }, [selectedTracks, musicType, updateQuery]);

  const columns = useMemo(
    () =>
      [
        {field: 'track_artist', headerName: 'Artist', flex: 1},
        {field: 'track_name', headerName: 'Song Title', flex: 1},
        GridDurationColumn,
        {
          field: 'id',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.track_category === 5 ? (
              <MusicPreviewBtnDisable />
            ) : params.row.url ? (
              <MusicPreviewBtn {...params.row} />
            ) : null,
        },
        {
          type: 'actions',
          field: 'actions',
          headerName: 'Action',
          hideable: false,
          width: 125,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.track_category === 5 ? (
              <NoPlayInfoButton id={params.row.id} />
            ) : selectedTrackIds.includes(params.row.id) ? (
              <SongSelectionRemoveButton
                onClick={() => onDeselect(params.row)}
              />
            ) : (
              <SongSelectionAddButton
                onClick={() => onSelect(params.row)}
                disabled={isAddDisabled}
              />
            ),
        },
      ].map(column => ({...column, sortable: false, filterable: false})),
    [selectedTrackIds, onSelect, onDeselect, isAddDisabled],
  );

  return (
    <Box className='w-full h-full flex-column justify-start'>
      <Box className='w-full h-62 flex flex-row justify-between items-center'>
        <Typography variant='h5' className='text-white font-bold'>
          {title || 'Select Songs'}
        </Typography>
        <InputSearch
          className='w-347'
          placeholder='Search Song'
          value={query.search || ''}
          onChange={e => updateQuery({search: e})}
        />
      </Box>
      <Box className='h-480 max-h-480 scroll-y' sx={{width: '99%'}}>
        <DataGridPro
          rows={trackDetails}
          columns={columns}
          loading={
            isTrackWithoutGenreRestriction ? isLoading : mediaTrackIsLoading
          }
          rowCount={
            isTrackWithoutGenreRestriction ? data?.total : mediaTrackData?.total
          }
          pageSizeOptions={[10, 25, 50]}
          paginationModel={{page: query.page, pageSize: query.pageSize}}
          paginationMode='server'
          pagination
          onPaginationModelChange={updateQuery}
          onSortModelChange={model => updateQuery({sort: model})}
          density='compact'
          disableRowSelectionOnClick
          disableColumnMenu
          slots={slots}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default MusicSelectionTable;
